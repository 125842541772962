import { faCheck, faLock, faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Feature, OemIndirect, PriceListItem } from '../../../../models/ApiModels';

interface Props {
  addonFeature: Feature;
  addAddon: (id: string) => void;
  active: boolean;
  added: boolean;
  customerPriceListItem?: PriceListItem;
  priceListItem?: PriceListItem;
  oemDetails?: OemIndirect;
}

export default function FunctionPackageAddon(props: Props) {
  const [hover, setHover] = useState(false);
  function handleMouseEnter() {
    setHover(true);
  }
  function handleMouseLeave() {
    setHover(false);
  }

  return (
    <div className={`px-5 py-5 flex justify-between items-center ${props.added ? 'bg-primary-10' : 'bg-gray-3 '}`}>
      <div className='col-span-2 flex items-center gap-2'>
        <h2 className='prose-mainMenuL2Default text-gray-100'>{props.addonFeature.displayName}</h2>
      </div>
      <div className='flex items-center gap-5'>
        {props.active ? (
          <span className='prose-heading5 text-gray-60'>Included in license </span>
        ) : (
          <div className='flex flex-col gap-1'>
            {props.customerPriceListItem?.unitPrice && (
              <>
                <span className='text-right text-gray-100 prose-heading5'>
                  {props.customerPriceListItem?.unitPrice.toLocaleString('en-US') +
                    ' ' +
                    (props.customerPriceListItem?.currencyCode || props.oemDetails?.currencyCode)}
                </span>
                {props.priceListItem?.unitPrice !== props.customerPriceListItem.unitPrice &&
                  props.customerPriceListItem.internalId &&
                  props.priceListItem && ( //only display retail price if there is a discount (which it almost always is)
                    <span className='prose-buttonTiny text-gray-60'>
                      (Retail price {props.priceListItem?.unitPrice.toLocaleString('en-US')}{' '}
                      {props.priceListItem?.currencyCode || props.oemDetails?.currencyCode})
                    </span>
                  )}
              </>
            )}
          </div>
        )}
        {props.active ? (
          <div className='w-10 h-10 rounded-full bg-gray-60 flex justify-center items-center'>
            <FontAwesomeIcon icon={faLock} size='lg' color='white' />
          </div>
        ) : props.added ? (
          <div
            className='w-10 h-10 bg-primary-200 rounded-full flex justify-center items-center cursor-pointer'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {hover ? (
              <div
                className='w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
                onClick={() => props.addAddon && props.addAddon(props.addonFeature.id)}
              >
                <FontAwesomeIcon icon={faXmark} size='lg' color='white' />
              </div>
            ) : (
              <FontAwesomeIcon icon={faCheck} size='lg' color='white' />
            )}
          </div>
        ) : (
          <div
            onClick={() => props.addAddon(props.addonFeature.id)}
            className='w-10 h-10 rounded-full flex border border-gray-60 text-gray-60 hover:bg-primary-200 hover:text-white justify-center items-center cursor-pointer'
          >
            <FontAwesomeIcon icon={faPlus} size='lg' />
          </div>
        )}
      </div>
    </div>
  );
}
