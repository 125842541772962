import { useMsal } from '@azure/msal-react';
import { faBullseye, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'component-library';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem } from '../../models/ApiModels';
import { GetUser, UpdateUser } from '../../services/UserService';
import useRequest from '../../utils/net/useRequest';
import { OemSelectionModal } from '../OemSelection/OemSelectionModal';
import { SidebarOemDropdownItem } from './SidebarOemDropdownItem';

export function SidebarOemDropdown() {
  const { accounts } = useMsal();
  const account = accounts[0];
  const navigate = useNavigate();
  const { currentOemTenantId, setCurrentOemTenantId, setCurrentOem, representingOem, setRepresentingOem } =
    useContext(AppContext);
  const [user = {}, isUserLoading, userGetError] = useRequest(
    () => GetUser(account?.idTokenClaims?.extension_oemUserId as string),
    [account]
  );
  const [oemFavourites, setOemFavourites] = useState<string[]>(user?.settings?.oemFavourites || []);
  const [chooseOem, setChooseOem] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const oems = useSelector((state: any) => state.oems.data);
  const ref = useDetectClickOutside({
    onTriggered: () => setDropdownOpen(false),
  });

  const currentOem = useMemo(() => {
    return oems.find((oem: Oem) => oem.id === currentOemTenantId);
  }, [currentOemTenantId, oems]);

  async function AddFavourite(id: string) {
    if (oemFavourites?.some((favourite: string) => favourite === id)) {
      const updatedList = user.settings.oemFavourites?.filter((favourite: string) => favourite !== id);
      setOemFavourites([...(updatedList || [])]);
      user.settings.oemFavourites = updatedList;
    } else {
      setOemFavourites([...oemFavourites, id]);
      user.settings.oemFavourites = [...oemFavourites, id];
    }
    await UpdateUser(user);
  }

  useEffect(() => {
    if (user?.settings?.oemFavourites) setOemFavourites([...user.settings.oemFavourites]);
  }, [user.settings?.oemFavourites]);

  const favouriteList = oems.filter((item: Oem) => {
    return oemFavourites?.some((fav: string) => fav === item.id);
  });

  return (
    <>
      {chooseOem && (
        <div className='absolute z-40 top-0 left-0 w-screen h-screen'>
          <div className='bg-black w-screen h-screen opacity-50'></div>
          <div className='absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 md:h-4/5 md:max-w-lg'>
            <OemSelectionModal
              loading={false}
              close={() => setChooseOem(false)}
              addFavourite={async (value: string) => await AddFavourite(value)}
              oemFavourites={oemFavourites}
            />
          </div>
        </div>
      )}
      <div className='relative flex w-full justify-center items-center'>
        <div
          className='flex flex-row h-[54px] w-full bg-white border border-gray-10 items-center justify-between p-[14px] cursor-pointer'
          onClick={(e: any) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
        >
          {currentOem !== undefined ? (
            <span className=' text-primary-400 prose-heading5 truncate'>{currentOem.name}</span>
          ) : (
            <div className='2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive'>
              <FontAwesomeIcon className='pr-2' icon={faBullseye} title='Home' />
              Humphree Home
            </div>
          )}
          <FontAwesomeIcon className='text-gray-100' icon={faChevronDown} title='Favorite' />
        </div>
        {dropdownOpen && (
          <div
            ref={ref}
            className='absolute flex flex-col w-[140%] bg-white shadow-dropdown top-[53px] left-0 z-20 border border-gray-10'
          >
            <div
              className={`${
                currentOemTenantId === '12345678-1234-1234-1234-123456789000' ? 'prose-heading5' : 'prose-paragraphBase'
              } text-gray-100 px-7 py-4 hover:bg-gray-5 cursor-pointer flex flex-row items-center border-b border-gray-10`}
              onClick={() => {
                setRepresentingOem(undefined);
                setCurrentOemTenantId('12345678-1234-1234-1234-123456789000');
                setDropdownOpen(!dropdownOpen);
                navigate('/');
              }}
            >
              <FontAwesomeIcon className='pr-2' icon={faBullseye} title='Home' />
              Humphree Home
            </div>
            <div className={'max-h-72 overflow-auto'}>
              {favouriteList.map((oem: Oem) => {
                const isActive = oem.id === currentOem?.id;

                return (
                  <SidebarOemDropdownItem
                    key={oem.id}
                    oem={oem}
                    oemChosen={() => {
                      setRepresentingOem(oem);
                      setCurrentOemTenantId(oem.id);
                      setDropdownOpen(!dropdownOpen);
                    }}
                    favouriteChanged={() => {
                      AddFavourite(oem.id);
                    }}
                    isFavourite={favouriteList}
                    isActive={isActive}
                  />
                );
              })}
            </div>
            <div className='flex self-center my-4 px-4'>
              <PrimaryButton
                label='Show all OEMs'
                onClick={() => {
                  setDropdownOpen(false);
                  setChooseOem(true);
                }}
                size='small'
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
