import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Snackbar } from 'component-library';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import { AppContext } from './contexts/AppContext';
import { Oem, OemIndirect } from './models/ApiModels';
import { Login } from './services/AuthService';
import { GetOem, GetOems, GetOemsIndirect } from './services/OemService';
import { ActionType } from './store/actionTypes';
import { RootState } from './store/reducers/combine';
import StoreInit from './store/StoreInit';

function App() {
  const humphreeOemTenantId = '12345678-1234-1234-1234-123456789000';
  const isAuthenticated = useIsAuthenticated();
  const { currentOemTenantId, setIsHumphreeUser, setCurrentOem, setCurrentOemTenantId, setRepresentingOem } =
    useContext(AppContext);
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState(true);
  const { accounts } = useMsal();
  const account = accounts[0];
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      Login();
      navigate('/');
    } else if (isAuthenticated) {
      const humphreeUser = account?.idTokenClaims?.extension_oemTenantId === humphreeOemTenantId;
      setCurrentOemTenantId(account?.idTokenClaims?.extension_oemTenantId as string);
      setIsHumphreeUser(humphreeUser);
      setLoading(true);
      if (humphreeUser) {
        GetOems()
          .then((oems: Oem[]) => {
            dispatch({
              type: ActionType.ADD_OEMS,
              payload: oems,
            });
            setLoading(false);
          })
          .catch((e: any) => {
            // Maybe do something here...
            setLoading(false);
            console.log(e);
          });
      } else {
        const oemTenantId = account?.idTokenClaims?.extension_oemTenantId as string;
        GetOem(oemTenantId)
          .then((res: Oem) => {
            setCurrentOem(res);
            setRepresentingOem(res);
            if (res.parentOEMTenantId === null) {
              GetOemsIndirect(oemTenantId)
                .then((oems: OemIndirect[]) => {
                  dispatch({
                    type: ActionType.ADD_OEMS,
                    payload: oems,
                  });
                  setLoading(false);
                })
                .catch((e: any) => {
                  setLoading(false);
                });
            }
            setCurrentOem(res);
            setRepresentingOem(res);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      StoreInit(dispatch, state);
    }
  }, [isAuthenticated, currentOemTenantId]);

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: ActionType.SET_SNACKBAR,
        payload: { heading: '', status: '' },
      });
    }, 4000);
  }, [state.snackbar.snackbar.heading]);

  return (
    <React.StrictMode>
      {isAuthenticated && (
        <div className='font-montserrat relative flex justify-center items-center w-screen h-screen'>
          {loading && (
            <div className='flex items-center flex-col gap-4'>
              <span className='text-primary-400 prose-heading2'>Loading app...</span>
              <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
            </div>
          )}

          {!loading && <Outlet />}
          {!loading && state.snackbar.snackbar.heading && (
            <div className='fixed bottom-6 z-50'>
              <Snackbar
                setVisibleSnackbar={state.snackbar.snackbar.heading !== ''}
                heading={state.snackbar.snackbar.heading}
                status={state.snackbar.snackbar.status}
                paragraph={state.snackbar.snackbar?.paragraph && state.snackbar.snackbar.paragraph}
                message={state.snackbar.snackbar?.message && state.snackbar.snackbar.message}
                onClick={() => {
                  dispatch({
                    type: ActionType.SET_SNACKBAR,
                    payload: { heading: '', status: '' },
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
    </React.StrictMode>
  );
}

export default App;
