import { faArrowRightToBracket, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup, PrimaryButton, StatusPill } from 'component-library';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem, OrderDetails } from '../../models/ApiModels';
import { GetOrderDetails, UpdateOrderDetails } from '../../services/OrdersService';
import { ActionType } from '../../store/actionTypes';
import { LoadingSpinner } from '../../utils/LoadingSpinner';
import { OrderDetailsInformation } from './OrderDetailsInformation';
import OrderDetailsOrderLines from './OrderDetailsOrderLines';

export function handleOrderStatusText(status: string, invoiced: boolean) {
  if (!invoiced) return 'Pending Invoice';

  switch (status) {
    case 'Completed':
      return 'Completed';
    default:
      return 'Created';
  }
}

export function handleOrderTypeText(order: string) {
  switch (order) {
    case 'CustomerUpgrade':
      return 'Customer upgrade';
    case 'LicenseUpgrade':
      return 'License upgrade';
    default:
      return 'Unknown';
  }
}

export function handleOrderStatusColor(order: string, invoiced: boolean) {
  if (!invoiced) return 'Light Red';
  switch (order) {
    case 'Completed':
      return 'Green';
    default:
      return 'Light Gray';
  }
}
const OrdersDetailsLayout = () => {
  const { currentOem, isHumphreeUser, isBusinessCustomer } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const { id } = useParams();
  const componentMounted = useRef(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<OrderDetails>();
  const [hasError, setHasError] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const customer = oems.find((oem: Oem) => oem.id === order?.oemTenantId)?.name || currentOem?.name;

  useEffect(() => {
    if (id) {
      GetDetails();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [id, order?.status]);

  function GetDetails() {
    setLoading(true);
    if (id !== undefined) {
      GetOrderDetails(id)
        .then((order: OrderDetails) => {
          if (componentMounted.current) {
            setOrder({ ...order });
          }
        })
        .catch(() => {
          setHasError(true);
          console.log('Something went wrong!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function updateOrderStatus() {
    if (id) {
      setLoading(true);
      setOpenPopup(false);
      UpdateOrderDetails(id)
        .then(() => {
          if (order) {
            order.status = 'Completed';
            order.invoiced = true;
          }
          setOrder(order);
          dispatch({
            type: ActionType.SET_SNACKBAR,
            payload: { heading: 'Marked as invoiced!', status: 'success' },
          });
        })
        .catch(() => {
          setHasError(true);
          console.log('Something went wrong!');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <div>
      {loading && <LoadingSpinner />}
      {order && !loading && (
        <>
          <div className='flex justify-between items-center mb-10'>
            <h1 className='prose-heading6 lg:prose-heading5 2xl:prose-heading3 items-center gap-1'>
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate('/orders');
                }}
              >
                Orders
              </span>
              <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
              {customer}
              <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
              {order.orderNumber}
            </h1>
            <StatusPill
              color={handleOrderStatusColor(order.status, order.invoiced)}
              text={handleOrderStatusText(order.status, order.invoiced)}
            />
          </div>
          {!order.invoiced && order.indirectCustomerOrderLines && (isHumphreeUser || isBusinessCustomer) && (
            <div className='w-full lg:ml-auto lg:w-fit flex flex-col gap-2 flex-wrap md:flex-nowrap md:flex-row lg:sticky top-0 right-0 z-10 my-6 /lg:mt-11 lg:-mb-8 /xl:mb-12 /xl:-mt-12'>
              <PrimaryButton
                fullWidth
                icon={faArrowRightToBracket}
                label={'Mark order as invoiced'}
                onClick={() => setOpenPopup(true)}
              />
            </div>
          )}
          <OrderDetailsInformation order={order} />
          <OrderDetailsOrderLines order={order} />
        </>
      )}
      {hasError && <p>Something went wrong!</p>}
      {openPopup && (
        <Popup
          close={() => setOpenPopup(false)}
          confirm={() => updateOrderStatus()}
          heading='Mark order as invoiced'
          paragraph='This means that you have manually created an order and invoiced your customer for their purchase.'
          confirmText='Mark as invoiced'
          status='primary'
          cancelText='Cancel'
        />
      )}
    </div>
  );
};

export default OrdersDetailsLayout;
